import { defineStore } from "pinia";
import { stringify } from "postcss";
import {
  Routes,
  HEADER_X_TOKEN,
  HEADER_TEAM_API_KEY,
  DEFAULT_PAGE_SIZE,
  apiServerUrl,
  HEADER_MEMBER_API_KEY,
  thankyouMailDefaultTemplate,
  thankyouMailDefaultHtmlCode,
  confirmationMailDefaultHtmlCode,
  confirmationMailDefaultTemplate,
  confirmationPageDefaultTemplate,
  confirmationPageDefaultHtmlCode,
} from "~/constants";

const state = {
  currentLists: {},
  uploadedFileId: null,
  listsCount: 0,
  lists: [],
  listContactCount: {},
  isUploading: false,
  uploadingPercent: 0,
  error: false,
  uploaded: false,
  matchFields: [],
  listContacts: [],
  listReq: {
    name: "",
    type: 0,
    sendThankyouEmail: false,
    thankyouEmailFromName: "",
    thankyouEmailFromEmail: "",
    thankyouMailSubject: "{{.FirstName}} Awesome to have you @Awesome Inc",
    thankyouMailMessage: thankyouMailDefaultHtmlCode,
    thankyouEmailTemplate: thankyouMailDefaultTemplate,
    thankyouSender: 0,
    thankyouSenderObject: null,
    confirmEmailFromName: "",
    confirmEmailFromEmail: "",
    confirmMailSubject: "Confirm to be a part of Awesome Inc.",
    confirmMailMessage: confirmationMailDefaultHtmlCode,
    confirmMailTemplate: confirmationMailDefaultTemplate,
    confirmSuccessPage: confirmationPageDefaultHtmlCode,
    confirmSuccessPageTemplate: confirmationPageDefaultTemplate,
    confirmSender: 0,
    confirmSenderObject: null,
  },
  splitListReq: {
    id: 0,
    name: "",
    size: 0,
    splitEqually: true,
    numberOfSplits: 2,
    parts: [
      {
        name: "Split 1",
        split_percentage: 50,
      },
      {
        name: "Split 2",
        split_percentage: 50,
      },
    ]

  },
};

const getBody = (listReq) => {
  return {
    name: listReq.name,
    type: listReq.type,
    send_thankyou_mail: listReq.sendThankyouEmail,
    thankyou_from_name: listReq.thankyouEmailFromName,
    thankyou_from_email: listReq.thankyouEmailFromEmail,
    thankyou_mail_subject: listReq.thankyouMailSubject,
    thankyou_mail_message: listReq.thankyouMailMessage,
    thankyou_mail_message_json: JSON.stringify(listReq.thankyouEmailTemplate),
    thankyou_sender: listReq.thankyouSender,
    confirm_from_name: listReq.confirmEmailFromName,
    confirm_from_email: listReq.confirmEmailFromEmail,
    confirm_mail_subject: listReq.confirmMailSubject,
    confirm_mail_message: listReq.confirmMailMessage,
    confirm_mail_message_json: JSON.stringify(listReq.confirmMailTemplate),
    confirm_success_page: listReq.confirmSuccessPage,
    confirm_success_page_json: JSON.stringify(
      listReq.confirmSuccessPageTemplate
    ),
    confirm_sender: listReq.confirmSender,
  };
};

export const useAudienceListStore = defineStore("audienceListStore", {
  state: () => state,
  persist: {
    storage: persistedState.sessionStorage,
  },
  getters: {
    getState: (state) => state,
    getAllList: (state) => state.lists,
    getListContactCount: (state) => state.listContactCount,
    getListContacts: (state) => state.listContacts,
  },
  actions: {
    setResource({ resource, value }) {
      this[resource] = value;
    },
    pushItem({ resource, value }) {
      if (!Array.isArray(this[resource])) {
        this[resource] = [];
      }
      this[resource].push(value);
    },
    setItem({ item, id, resource }) {
      if (!this[resource]) {
        this[resource] = {};
      }
      this[resource][id] = item;
    },

    resetListReq() {
      const globalStore = useGlobalStore();

      this.setResource({
        resource: "listReq",
        value: {
          name: "",
          type: 0,
          sendThankyouEmail: false,
          thankyouEmailFromName: "",
          thankyouEmailFromEmail: "",
          thankyouMailSubject:
            "{{.FirstName}} Awesome to have you @Awesome Inc",
          thankyouMailMessage: thankyouMailDefaultHtmlCode,
          thankyouEmailTemplate: thankyouMailDefaultTemplate,
          thankyouSender: globalStore.$state?.currentTeam?.default_sender?.id,
          thankyouSenderObject: null,
          confirmEmailFromName: "",
          confirmEmailFromEmail: "",
          confirmMailSubject: "Confirm to be a part of Awesome Inc.",
          confirmMailMessage: confirmationMailDefaultHtmlCode,
          confirmMailTemplate: confirmationMailDefaultTemplate,
          confirmSuccessPage: confirmationPageDefaultHtmlCode,
          confirmSuccessPageTemplate: confirmationPageDefaultTemplate,
          confirmSender: globalStore.$state?.currentTeam?.default_sender?.id,
          confirmSenderObject: null,
        },
      });
    },

    refreshListData() {
      this.setResource({ resource: "lists", value: [] });
      this.setResource({ resource: "listsCount", value: 0 });
      this.setResource({ resource: "listContactCount", value: {} });
      this.setResource({ resource: "listContacts", value: [] });
    },

    fetchAllLists({ offset, limit, order, entity, search }) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        let xoffset = offset ?? 0;
        let xlimit = limit ?? DEFAULT_PAGE_SIZE;
        let xentity = entity ?? "";
        let xorder = order ?? "";

        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/list`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          params: {
            offset: xoffset,
            limit: xlimit,
            search: search,
            entity: xentity,
            order: xorder,
          },
          key: "listSearch",
        })
          .then((resp) => {
            const lists = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("get lists error : ", error);
              reject(error);
            } else {
              console.log("Lists data : ", lists);
              this.setResource({
                resource: "lists",
                value: lists,
              });
              resolve(lists);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchListsRevenue({ ids }) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/list/revenue/stats`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: JSON.stringify({ ids }),
          key: "listSearch",
        })
          .then((resp) => {
            const listsRevenue = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("get lists error : ", error);
              reject(error);
            } else {
              const updatedLists = this.lists?.map((nl, index) => {
                const revenueStat = listsRevenue.find((nrs) => nrs.id == nl.id);
                return {
                  ...nl,
                  totalRevenue: revenueStat ? revenueStat.totalRevenue : "",
                  revenuePerContact: revenueStat
                    ? revenueStat.revenuePerContact
                    : "",
                };
              });
              console.log("updated lists ", updatedLists);
              this.setResource({
                resource: "lists",
                value: updatedLists,
              });
              resolve(updatedLists);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchAllListCount({ search } = {}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/list/count`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          params: {
            search: search,
          },
        })
          .then((resp) => {
            const listCount = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("List count error : ", error);
              reject(error);
            } else {
              this.setResource({
                resource: "listsCount",
                value: listCount.count,
              });
              resolve(listCount);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },
    getListById(id) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(`${apiServerUrl()}team/list/` + id, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((resp) => {
            const list = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("List get error : ", error);
              reject(error);
            } else {
              this.setItem({
                resource: "currentLists",
                id: id,
                item: list,
              });
              resolve(list);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },
    splitList(id) {
      console.log("in store split list")
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(`${apiServerUrl()}team/list/` + id + "/split", {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: JSON.stringify(this.splitListReq.parts)
        })
          .then((resp) => {
            console.log("in store split lis 1t")

            const list = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("List get error : ", error);
              reject(error);
            } else {
              console.log("in store split lis 2t")

              resolve(list);
            }
          })
          .catch((error) => {
            console.log("in store split list 43")

            console.log("error -> ", error);
            reject(error);
          });
      });
    },
    updateList({ id }) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(`${apiServerUrl()}team/list/` + id, {
          method: "PUT",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: getBody(this.listReq),
        })
          .then((resp) => {
            const res = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("List update error : ", error);
              reject(error);
            } else {
              console.log("list response : ", res);
              resolve(res);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },
    createList() {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/list`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: getBody(this.listReq),
        })
          .then((resp) => {
            const data = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("create list error => ", error);
              reject(error);
            } else {
              resolve(data);
            }
          })
          .catch((err) => {
            console.log(err, "in catch in creating list");
            reject(err);
          });
      });
    },
    uploadListCSV(e) {
      const formData = new FormData();
      formData.append("fileinput", e);
      const store = useGlobalStore();
      const audienceListStore = useAudienceListStore();

      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        const xhr = new XMLHttpRequest();

        xhr.open("POST", `${apiServerUrl()}team/list/import`, true);

        // Set the API key header
        xhr.setRequestHeader(HEADER_TEAM_API_KEY, apiKey);
        xhr.setRequestHeader(HEADER_MEMBER_API_KEY, memberApiKey);

        // Monitor upload progress
        xhr.upload.onprogress = function (event) {
          if (event.lengthComputable) {
            console.log(event, "event");
            const percentComplete = (event.loaded / event.total) * 100;
            console.log(`Upload progress: ${percentComplete.toFixed(2)}%`);
            // this.uploadingPercent = percentComplete.toFixed(2);
            audienceListStore.setResource({
              resource: "uploadingPercent",
              value: percentComplete.toFixed(2),
            });
            // Update your UI with the progress percentage if needed
          }
        };

        // Handle successful upload
        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              const response = JSON.parse(xhr.responseText);
              // const data = response.data?.value;
              // const error = response.error?.value;

              console.log("uploadListCSV", response);
              if (!response) {
                console.log("error ::: ");
                reject({ message: "Error while uplaoding" });
              } else {
                console.log("this.uploadedFileId");
                audienceListStore.setResource({
                  resource: "uploadedFileId",
                  value: response.id,
                });
                resolve(response);
              }
            } catch (error) {
              console.log("Parsing error -> ", error);
              reject(error);
            }
          } else {
            console.log("error -> ", xhr.statusText);
            reject(xhr.statusText);
          }
        };

        // Handle network error
        xhr.onerror = function () {
          console.log("Network error -> ", xhr.statusText);
          reject(xhr.statusText);
        };

        // Send the form data
        xhr.send(formData);
      });
    },
    mapContactsToList(id) {
      const store = useGlobalStore();
      const apiKey = store.$state?.currentTeam?.apiKey ?? "";
      const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
      const body = {
        fileId: this.uploadedFileId,
        contactProperties: this.matchFields,
      };

      return new Promise((resolve, reject) => {
        useFetch(`${apiServerUrl()}team/list/` + id + `/mapping`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: body,
        })
          .then((resp) => {
            const data = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("error :: ", error);
              reject(error);
            } else {
              console.log("mapContactsToList : ", data);
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },
    manualMapContactsToList({ id, text }) {
      const store = useGlobalStore();
      const apiKey = store.$state?.currentTeam?.apiKey ?? "";
      const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
      const body = {
        data: text,
      };

      return new Promise((resolve, reject) => {
        useFetch(`${apiServerUrl()}team/list/` + id + `/mapping/manual`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: JSON.stringify(body),
        })
          .then((resp) => {
            const data = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("error in manualMapContactsToList:: ", error);
              reject(error);
            } else {
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },
    fetchAllListContacts({ offset, limit, contactType, search, id }) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        let xoffset = offset ?? 0;
        let xlimit = limit ?? DEFAULT_PAGE_SIZE;
        let xcontactType = contactType ?? "";

        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/list/` + id + "/contact", {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          params: {
            offset: xoffset,
            limit: xlimit,
            search: search,
            contactType: xcontactType,
          },
        })
          .then((resp) => {
            const contacts = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("get contacts error : ", error);
              reject(error);
            } else {
              console.log("contacts data : ", contacts);
              this.setResource({
                resource: "listContacts",
                value: contacts,
              });
              resolve(contacts);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchListContactCount({ id, search }) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(apiServerUrl() + "team/list/" + id + "/contact/count", {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          params: {
            search: search,
          },
        })
          .then((resp) => {
            const listCount = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("List contact count error : ", error);
              reject(error);
            } else {
              console.log("List contact count : ", listCount.count);
              this.setItem({
                resource: "listContactCount",
                id: id,
                item: listCount.count,
              });
              resolve(listCount.count);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    fetchListsContactCount({ lids }) {
      const store = useGlobalStore();
      const lStore = useAudienceListStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        const data = {
          ids: lids,
        };

        useFetch(apiServerUrl() + "team/list/contact/counts", {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: JSON.stringify(data),
        })
          .then((resp) => {
            const listCountArray = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("List contact count2 error : ", error);
              reject(error);
            } else {
              listCountArray.forEach((element) => {
                this.setItem({
                  resource: "listContactCount",
                  id: element.entityId,
                  item: element.contactCount,
                });
              });

              resolve(listCountArray);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    deleteListById({ id, deleteContacts }) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        const body = {
          deleteContacts: deleteContacts,
        };

        useFetch(apiServerUrl() + "team/list/" + id, {
          method: "DELETE",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: body,
        })
          .then((resp) => {
            const deleteRes = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("List delete error : ", error);
              reject(error);
            } else {
              console.log("deleteRes : ", deleteRes);
              resolve(deleteRes);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },
    exportListById({ id }) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(`${apiServerUrl()}team/list/` + id + "/export", {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((resp) => {
            const exportRes = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("List export error : ", error);
              reject(error);
            } else {
              console.log("exportRes : ", exportRes);
              resolve(exportRes);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },
  },
});
