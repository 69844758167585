<template>
    <div class="relative rounded-md shadow-sm">
        <div v-if="showIcon" class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
						width="15"
						height="15"
						viewBox="0 0 15 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M10.5625 6.4375C10.5625 4.87891 9.71484 3.45703 8.375 2.66406C7.00781 1.87109 5.33984 1.87109 4 2.66406C2.63281 3.45703 1.8125 4.87891 1.8125 6.4375C1.8125 8.02344 2.63281 9.44531 4 10.2383C5.33984 11.0312 7.00781 11.0312 8.375 10.2383C9.71484 9.44531 10.5625 8.02344 10.5625 6.4375ZM9.71484 10.9219C8.73047 11.6875 7.5 12.125 6.1875 12.125C3.04297 12.125 0.5 9.58203 0.5 6.4375C0.5 3.32031 3.04297 0.75 6.1875 0.75C9.30469 0.75 11.875 3.32031 11.875 6.4375C11.875 7.77734 11.4102 9.00781 10.6445 9.99219L14.3086 13.6289C14.5547 13.9023 14.5547 14.3125 14.3086 14.5586C14.0352 14.832 13.625 14.832 13.3789 14.5586L9.71484 10.9219Z"
							fill="#3B82F6" />
					</svg>
        </div>
        <input autocomplete="off" data-lpignore="true"  :disabled="disabled" :value="value" :placeholder="placeholder" @input="handleInput" type="email" name="email"
            id="email"
            :class="[
                'block w-full rounded-md border-solid focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 placeholder:text-gray-300 placeholder:font-normal placeholder:text-sm focus:ring-1 focus:ring-inset sm:leading-6', { 'pl-10': showIcon }, 'py-' + py, 'h-' + h]" />
    </div>
</template>
  
<script setup>

const props = defineProps({
    showIcon: {
        type: Boolean,
        default: false
    },
    value: {
        type: String || Number,
        required: true
    },
    placeholder: {
        type: String,
        default: "Search..."
    },
    py: {
        type: String,
        default: '1.5'
    },
    h: {
        type: String,
        default: 'fit'
    },
    disabled: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['input']);

const handleInput = (e) => {
    emit('input', e);
}
</script>
  