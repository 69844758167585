import revive_payload_client_4sVQNw7RlN from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BqLkNUef68 from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import slideovers_LDumGYo2KH from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_OVoKJro5pc from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import floating_vue_EIcJ7xiw0h from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/.nuxt/floating-vue.mjs";
import chunk_reload_client_UciE0i6zes from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import v_click_outside_tGcsVo1qCA from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/v-click-outside.js";
import api_QQzOodE9HA from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/api.js";
import vue_plyr_BnNbaz6IPd from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/vue-plyr.js";
import vue_apexcharts_QL9RVcDRjZ from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/vue-apexcharts.js";
import vue_tooltip_ZeWdLfYluO from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/vue-tooltip.js";
import rich_editor_O3MLdycf2z from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/rich-editor.js";
import jquery_SqE5xeO2Mp from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/jquery.js";
import vue_tailwind_datepicker_Witns8p0r2 from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/vue-tailwind-datepicker.js";
import color_picker_8ESqlUZOgb from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/color-picker.js";
import vue_paginate_hdRk3l7HKU from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/vue-paginate.js";
import sentry_PZVGm6LH3G from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/sentry.js";
import error_handler_VFH3VvK7yG from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/error-handler.js";
import remove_console_logs_dGA7qMJe5q from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/remove-console-logs.js";
import smartlook_GjSe4n0jve from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/smartlook.js";
import vue_draggable_resizeable_NjDpQwGE2U from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/vue-draggable-resizeable.js";
import ably_7rPdum4Oag from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/ably.js";
import firebasse_client_9Kffu3V5qH from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/plugins/firebasse.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BqLkNUef68,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_OVoKJro5pc,
  floating_vue_EIcJ7xiw0h,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  v_click_outside_tGcsVo1qCA,
  api_QQzOodE9HA,
  vue_plyr_BnNbaz6IPd,
  vue_apexcharts_QL9RVcDRjZ,
  vue_tooltip_ZeWdLfYluO,
  rich_editor_O3MLdycf2z,
  jquery_SqE5xeO2Mp,
  vue_tailwind_datepicker_Witns8p0r2,
  color_picker_8ESqlUZOgb,
  vue_paginate_hdRk3l7HKU,
  sentry_PZVGm6LH3G,
  error_handler_VFH3VvK7yG,
  remove_console_logs_dGA7qMJe5q,
  smartlook_GjSe4n0jve,
  vue_draggable_resizeable_NjDpQwGE2U,
  ably_7rPdum4Oag,
  firebasse_client_9Kffu3V5qH
]