<template>
  <div
    class="flex flex-col items-center justify-center"
    tabindex="-1"
    role="listbox"
    aria-labelledby="listbox-label"
    ref="optionsRef"
  >
    <div
      v-if="
        !queryInProgress && filteredContact.length === 0 
      "
      class="flex flex-col items-center justify-center mt-[30px]"
    >
      <h1 class="text-sm text-[#111827]" v-if="!isTypesenseSearchEnabled">
        Enter full email
      </h1>
      <h1 class="text-sm text-[#6B7280] w-[300px] text-center" v-if="isTypesenseSearchEnabled">
        Enter first name, last name or the email address of the contact
      </h1>
      <h1
        class="text-xs text-[#6B7280] w-[300px] text-center"
        v-if="!isTypesenseSearchEnabled"
      >
        or try different keywords, check for any possible typos or reach out to
        <span class="text-blue-500">support</span>
      </h1>
    </div>
    <ul class="w-full mt-[20px]">
      <li v-if="queryInProgress" class="font-semibold px-3 pb-1 text-md">
        <SpinnersThreeDotsBlack />
      </li>
      <div v-else-if="!queryInProgress && filteredContact?.length > 0" class="h-[350px] overflow-y-auto">
        <li
          v-for="(contact, index) in filteredContact"
          :key="contact.id"
          @click.stop="gotoContact(contact?.id)"
          class="w-full flex flex-row justify-between cursor-pointer hover:bg-blue-100 rounded p-3"
          role="option"
        >
          <div class="flex flex-row items-center gap-[10px]">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="16" r="16" fill="#F9FAFB" />
              <path
                d="M17 15.75C15.7422 15.75 14.5938 15.0938 13.9648 14C13.3359 12.9336 13.3359 11.5938 13.9648 10.5C14.5938 9.43359 15.7422 8.75 17 8.75C18.2305 8.75 19.3789 9.43359 20.0078 10.5C20.6367 11.5938 20.6367 12.9336 20.0078 14C19.3789 15.0938 18.2305 15.75 17 15.75ZM15.7422 17.0625H18.2305C20.9375 17.0625 23.125 19.25 23.125 21.957C23.125 22.3945 22.7422 22.75 22.3047 22.75H11.668C11.2305 22.75 10.875 22.3945 10.875 21.957C10.875 19.25 13.0352 17.0625 15.7422 17.0625Z"
                fill="#6B7280"
              />
            </svg>
            <div class="flex flex-col">
              <h1 class="text-sm text-[#111827]">
                {{ !isTypesenseSearchEnabled ? contact?.first_name  : contact?.firstName}}
                {{ !isTypesenseSearchEnabled ? contact?.last_name  : contact?.lastName}}
              </h1>
              <h1 class="text-xs text-[#6B7280]">
                {{ contact?.email }}
              </h1>
            </div>
          </div>
          <div class="flex items-center">
            <h1 class="text-xs text-[#6B7280]">Contact</h1>
          </div>
        </li>
        <li
        class="w-full text-center cursor-pointer hover:bg-blue-100 rounded p-3 text-gray-500 text-[14px]"
        @click="showMoreClicked"
        v-if="totalCount > 0 && showMoreVisible"
        >
        Show More
        </li>
      </div>
	 
    </ul>
  </div>
</template>

<script setup>
import { Routes } from "~/constants";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { SpinnersThreeDotsBlack } from "#components";
// const searchValue = ref("")

const store = useGlobalStore();

const props = defineProps({
  searchValue: {
    deafutl: "",
    type: String,
  },
  queryInProgress: {
    type: Boolean,
    default: false,
  },
  filteredContact: {
    default: [],
  },
  totalCount:{
    type: Number,
    default:0
  }
});

const emit = defineEmits(["resetValue", "showMore"]);

const gotoContact = async (id) => {
  store.setResource({ resource: "showSearch", value: false });
  await navigateTo(`${Routes.Audience.Contact.Info.Home}/${id}`);
};

const showMoreClicked  = () => {
  emit("showMore")
} 

const isTypesenseSearchEnabled = computed(() => {
  return store.$state.currentTeam?.isTypesenseSearchEnabled;
});

const showMoreVisible = computed(()=>{
  let t =  props.totalCount - props.filteredContact?.length > 0
  console.log("t here => ", t)
  return t
})
</script>
