<template>
	<div class="mt-[20px]">
		<div class="flex flex-row gap-[30px]">
			<div
				class="w-[30%]"
				v-if="!isTyping">
				<AisRefinementList
					show-more
					:sort-by="['name:asc', 'count:desc']"
					attribute="categories">
					<template
						v-slot="{
							items,
							isShowingMore,
							isFromSearch,
							canToggleShowMore,
							refine,
							createURL,
							toggleShowMore,
							searchForItems,
							sendEvent,
						}">
						<div>
							<ul class="overflow-y-scroll max-h-[500px]">
								<li
									class="text-sm w-full py-[10px] px-[15px] rounded-md"
									v-for="item in items"
									:style="{
										backgroundColor: item.isRefined ? '#F3F4F6' : '',
									}"
									:key="item.value">
									<a
										class="flex flex-row justify-between"
										:href="createURL(item)"
										@click.prevent="refine(item.value)">
										<span>{{ item.value }}</span>
									</a>
								</li>
							</ul>
							<button
								class="text-sm text-center w-full py-[10px] px-[15px] mt-[10px]"
								@click="toggleShowMore"
								:disabled="!canToggleShowMore">
								{{ !isShowingMore ? "Show more" : "Show less" }}
							</button>
						</div>
					</template>
				</AisRefinementList>
			</div>
			<div :class="[!isTyping ? 'w-[70%]' : 'w-full']">
				<div class="overflow-y-scroll">
					<div class="mb-[15px]">
						<h1
							v-if="!isTyping"
							class="text-[#6B7280] text-sm font-normal">
							Video tutorals
						</h1>
					</div>
					<div class="flex flex-col gap-[15px]">
						<AisHits class="h-[500px]">
							<template v-slot="{ items }">
								<div
									v-if="items.length === 0"
									class="flex flex-col items-center justify-center mt-[30px]">
									<h1 class="text-sm text-[#111827]">
										Enter full email
									</h1>
									<h1
										class="text-xs text-[#6B7280] w-[300px] text-center">
										or try different keywords, check for any possible
										typos or reach out to
										<span class="text-blue-500">support</span>
									</h1>
								</div>
								<div v-else>
									<div class="mb-[15px]">
										<h1
											v-if="isTyping"
											class="text-[#6B7280] text-sm font-normal">
											{{ items.length }} search results
										</h1>
									</div>
									<div
										class="grid gap-[20px]"
										:class="[
											!isTyping ? 'grid-cols-2' : 'grid-cols-3',
										]">
										<div
											v-for="item in items"
											@click="handlePlayVideo(item)"
											class="col-span-1 flex flex-col gap-2 cursor-pointer">
											<div class="w-full rounded-md">
												<!-- <img
													class="rounded-md"
													src="https://s3-alpha-sig.figma.com/img/7c26/3aff/b9eb62b14b0995663f64108d4ba2c169?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WaLgVPx6SZGIN8UbhsfbiRUj1uHlJBz0GybBs9C-8jltm3HzP6MKBJ90YOtDJHgget3ZxxhvRTzxfbhKII3BSsokvKxdY2dGQXb2ji6HUIiJ-KOCNPi0i2rbHsLEQVxgmL6K-vZNM53Y9aOftQ-e6L6HiQ5wvL2pXmgW-yFlzdmDqzJQUK~dzJ-SXRZYO~JfroKA80uia02PsDAPwXs95iTrzEsDzAeKPD8SPDwqDFe4Xf4xwj7dfi1DzZuo07VfW69RSmnXQTseWF6N3P1mD4YCr1Ny4MTwzR6ZUGO3RppmVsaY9njAwVjt9wssa8ilJDIcpBf7~sF-ySbB7F0jjQ__" /> -->

												<div
													style="
														pointer-events: none;
														position: relative;
														padding-bottom: 56.25%;
														height: 0;
														border-radius: 0;
														overflow: hidden;
														/* min-width: 100px; */
														border-radius: 6px;
														background-color: white;
													">
													<div
														v-if="showVideoLoader"
														id="loader"
														class="absolute top-0 left-0 w-full h-full bg-gray-100 animate-pulse"></div>
													<iframe
														:src="getUrl(item.src)"
														frameborder="0"
														webkitallowfullscreen
														mozallowfullscreen
														allowfullscreen
														allow="autoplay; fullscreen"
														style="
															position: absolute;
															top: 0;
															left: 0;
															width: 100%;
															height: 100%;
															z-index: 50;
														"
														@load="hideLoader"></iframe>
												</div>
											</div>

											<div class="flex flex-col gap-[5px] w-fit">
												<div
													class="flex flex-row gap-2 items-center text-xs text-[#6B7280]">
													<h1 class="text-xs">
														{{ item.views }} Views
													</h1>
													<div
														class="h-[3px] w-[3px] rounded-full bg-[#6B7280]"></div>
													<h1>{{ item.daysAgo }} days ago</h1>
												</div>
												<div>
													<h1
														:title="item.title"
														class="text-sm text-[#111827] w-[230px] truncate">
														{{ item.title }}
													</h1>
												</div>
												<div
													class="flex flex-row gap-[5px] items-center">
													<div>
														<svg
															width="12"
															height="13"
															viewBox="0 0 12 13"
															fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																d="M10.875 6.5C10.875 4.76562 9.9375 3.17188 8.4375 2.28125C6.91406 1.41406 5.0625 1.41406 3.5625 2.28125C2.03906 3.17188 1.125 4.76562 1.125 6.5C1.125 8.25781 2.03906 9.85156 3.5625 10.7422C5.0625 11.6094 6.91406 11.6094 8.4375 10.7422C9.9375 9.85156 10.875 8.25781 10.875 6.5ZM0 6.5C0 4.36719 1.125 2.39844 3 1.32031C4.85156 0.242188 7.125 0.242188 9 1.32031C10.8516 2.39844 12 4.36719 12 6.5C12 8.65625 10.8516 10.625 9 11.7031C7.125 12.7812 4.85156 12.7812 3 11.7031C1.125 10.625 0 8.65625 0 6.5ZM5.4375 3.3125C5.4375 3.00781 5.67188 2.75 6 2.75C6.30469 2.75 6.5625 3.00781 6.5625 3.3125V6.21875L8.55469 7.53125C8.8125 7.71875 8.88281 8.07031 8.71875 8.32812C8.53125 8.58594 8.17969 8.65625 7.92188 8.46875L5.67188 6.96875C5.53125 6.875 5.4375 6.6875 5.4375 6.5V3.3125Z"
																fill="#6B7280" />
														</svg>
													</div>
													<h1 class="text-sm text-[#6B7280]">
														{{ item.duration }}
													</h1>
												</div>
											</div>
											<!-- <div
												style="
													position: relative;
													padding-bottom: 56.25%;
													height: 0;
													border-radius: 0;
													overflow: hidden;
													/* min-width: 100px; */
													border-radius: 10px 10px 0px 0px;
													background-color: white;
												">
												<iframe
													:src="getUrl(item.src)"
													frameborder="0"
													webkitallowfullscreen
													mozallowfullscreen
													allowfullscreen
													allow="autoplay; fullscreen"
													style="
														position: absolute;
														top: 0;
														left: 0;
														width: 100%;
														height: 100%;
														z-index: 50;
													"></iframe>
											</div> -->
										</div>
									</div>
								</div>
							</template>
						</AisHits>
						<AisConfigure
							:hits-per-page.camel="10"
							:distinct="true"
							:analytics="false"
							:enable-personalization.camel="true" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<!-- 
<style scoped>
.t-player .t-player--embed .t-player__icon span {
	display: none !important;
}
</style> -->

<script setup>
import {
	AisHits,
	AisRefinementList,
	AisConfigure,
} from "vue-instantsearch/vue3/es";

const showVideoLoader = ref(true);
const props = defineProps({
	isTyping: {
		default: false,
	},
});

const hideLoader = () => {
	console.log("loading finished");
	showVideoLoader.value = false;
};

const store = useGlobalStore();

const pushToSlack = async (src) => {
	console.log(src);
	try{
		const res = await store.sendSlackNotification({email: store.$state.authInfo.account.Email, title: src.title, url: src.src});
	}catch(e){
		console.log(e);
	}
};

const handlePlayVideo = async (src) => {
	store.setResource({ resource: "showSearch", value: false });
	store.setResource({ resource: "showVideoPlayer", value: true });
	store.setResource({ resource: "selectedVideoDetails", value: src });
	await pushToSlack(src);
};

const getUrl = (url) => {
	const splitArr = url.split("/");
	const videoId = splitArr[splitArr.length - 1];
	return "https://video.sendworks.com/share/" + videoId + "/embed";
};
</script>
