import { defineStore } from "pinia";
import {
  Routes,
  HEADER_X_TOKEN,
  HEADER_TEAM_API_KEY,
  HEADER_MEMBER_API_KEY,
  apiServerUrl,
} from "~/constants";

const state = {
    contactRequest:{
      firstName:'',
      lastName:'',
      email:'',
      company:'',
      lists:[],
      tags:[],
      contactSource:null,
      customFields:''
    },
    contactEventGroups:null,
    contactProfile:{
      contact:{},
      lists:[],
      tags:[],
      campaigns:[],
      workflows:[],
      customProperties:{},
    },
    contacts:[],
    contactCount:0,
    existContactId:null,
    allContactTypeCount : {},
  }


  function toCamelCase(str) {
    return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
  }
  
  function convertKeysToCamelCase(obj) {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = toCamelCase(key);
      acc[camelCaseKey] = obj[key];
      return acc;
    }, {});
  }

export const useAudienceContactStore = defineStore("audienceContactStore", {
  state: () => state,
  persist: {
    storage: persistedState.sessionStorage,
  },
  getters: {
    getState: (state) => state,
    getContacts : (state) => state.contacts,
    getContactProfileContact : (state) => state.contactProfile.contact,
    getContactProfile : (state) => state.contactProfile,
    getContactCount : (state) => state.contactCount,
    getExistContactId : (state) => state.existContactId,
    getList : (state) => state.contactProfile.lists,
    getTag : (state) => state.contactProfile.tags
  },
  actions: {
    setResource({ resource, value }) {
        this[resource] = value
    },

    setItem({ item, id, resource }) {
      if (!this[resource]) {
        this[resource] = {};
      }
      this[resource][id] = item;
    },

    refreshAllContactData() {
      this.setResource({resource: "contacts", value: []})
      this.setResource({resource: "contactCount", value: 0})
    },

    refreshContactProfileState() {
      console.log("Jitu inside reset State")
      this.setItem({resource : "contactProfile",id : "contact",item:{}})
      this.setItem({resource : "contactProfile",id : "lists",item:[]})
      this.setItem({resource : "contactProfile",id : "tags",item:[]})
      this.setItem({resource : "contactProfile",id : "workflows",item:[]})
      this.setItem({resource : "contactProfile",id : "customProperties",item:[]})
      this.setResource({resource : "contactEventGroups", value : null})
    },

    fetchAllContacts({contactType,offset,limit,search}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          query: { contactType, offset, limit,search}
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`Contacts ${contactType} error : `, error);
              reject(error);
            } else {
              console.log(`Contacts ${contactType} data : `, data);
              this.setResource({
                resource: "contacts",
                value: data,
              });
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    fetchAllContactsCount({contactType,search}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact/count`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          query:{contactType,search}
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`Contacts ${contactType} Count error : `, error);
              reject(error);
            } else {
              console.log(`Contacts ${contactType} Count data : `, data);
              this.setResource({
                resource: "contactCount",
                value: data,
              });
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    fetchAllContactTypeCount() {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact/all/count`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`Contacts type Count error : `, error);
              reject(error);
            } else {
              console.log(`Contacts type Count data : `, data);
              this.setResource({
                resource: "allContactTypeCount",
                value: data,
              });
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    deleteContact({Id}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact/${Id}`, {
          method: "DELETE",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`Contacts Delete error : `, error);
              reject(error);
            } else {
              console.log(`Contacts Delete data : `, data);
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    getContactById({Id}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(`${apiServerUrl()}team/contact/${Id}`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`Get Contacts Id error : `, error);
              reject(error);
            } else {
              console.log(`Get Contacts Id data : `, data);
              this.setItem({resource : "contactProfile",item : data,id : "contact"}) // If list comes as a response than remove this -> this.getContactProfileContact
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    getCustomFieldsForAContact({ids}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        const data = {
          ids : ids
        }

        useFetch(`${apiServerUrl()}team/contact/customfield/multiple`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: JSON.stringify(data),
          key: ids.join("-"),
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`Get cs by id error : `, error);
              reject(error);
            } else {
              console.log(`Get cs by id data : `, data);
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    updateContact({Id}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact/${Id}`, {
          method: "PUT",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body:this.contactRequest
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`update Contacts error : `, error);
              reject(error);
            } else {
              console.log(`update Contacts data : `, data);
              this.setItem({resource : "contactProfile",item : data,id : "contact"})
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    createContact() {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body:this.contactRequest
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`create Contacts error : `, error);
              reject(error);
            } else {
              console.log(`create Contacts data : `, data);
              if(data.status === "Contact Already Exist"){
                this.setResource({resource:"existContactId",value:data.data})
              }
              resolve(data)
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    getContactEvent({Id, limit, offset}) {
      const store = useGlobalStore();
      console.log("offset", offset, "limit", limit)
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact/${Id}/event`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          query: { offset, limit}
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`Get Contacts Event error : `, error);
              reject(error);
            } else {
              console.log(`Get Contacts Event Data : `, data);
              this.setResource({resource:"contactEventGroups",value:data})
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    getContactEventCount({Id}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact/${Id}/event/count`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          }
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`Get Contacts Event count error : `, error);
              reject(error);
            } else {
              console.log(`Get Contacts Event count Data : `, data);
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },


    getContactList({Id}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact/${Id}/list`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`Get Contacts Lists error : `, error);
              reject(error);
            } else {
              console.log(`Get Contacts Lists Data : `, data);
              this.setItem({resource : "contactProfile",item : data,id : "lists"})
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    getContactTag({Id}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact/${Id}/tag`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`Get Contacts Tags error : `, error);
              reject(error);
            } else {
              console.log(`Get Contacts Tags Data : `, data);
              this.setItem({resource : "contactProfile",item : data,id : "tags"})
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    unsubscribedContact({Id}){
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact/unsubscribe/${Id}`, {
          method: "PUT",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`unsubscribed contact error : `, error);
              reject(error);
            } else {
              console.log(`unsubscribed contact Data : `, data);
              this.contactProfile.contact.unsubscribed = true
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },


    subscribedContact({Id}){
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact/subscribe/${Id}`, {
          method: "PUT",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log(`subscribed contact error : `, error);
              reject(error);
            } else {
              console.log(`subscribed contact Data : `, data);
              this.contactProfile.contact.unsubscribed = false
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    fetchContactByEmail({search}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/contact/email`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          params: {
            search: search,
          }
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              reject(error);
            } else {
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    searchContactViaTypesense({query, limit, offset}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/typesense/search`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
          },
          body: JSON.stringify({
            query,
            limit,
            offset
          }),
          key: "searchViaTypesense"
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              reject(error);
            } else {
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    checkContactInExcludedSegments({excludedSegments, contactId}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        const data = {
          segments: excludedSegments,
        }
        useFetch(`${apiServerUrl()}team/contact/${contactId}/exists/listtagsegment`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: JSON.stringify(data)
        })
          .then((res) => {
            const data = res.data.value;
            const error = res.error.value;
            if (error) {
              reject(error);
            } else {
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    exportContacts({contactType}) {
      return new Promise((resolve, reject) => {
        const globalStore = useGlobalStore();
        const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = globalStore.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(apiServerUrl() + "team/contact/export", {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          query:{contactType}
        }).then((res) => {
            const exportRes = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log("export last x days newsletter error: ", error);
              reject(error);
            } else {
              console.log("exportRes : ", exportRes)
              resolve(exportRes);
            }
          }
        ).catch((e) => {
          reject(e);
        });
      });
    },

  },
});
