<template>
	<UseDraggable
		:handle="handle"
		class="overflow-auto fixed aspect-[16/13] min-w-[500px] max-w-[1000px] min-h-[400px] max-h-[700px] w-[500px] h-fit right-[10px] z-[51] p-1 pr-1 block resize"
		style="position: fixed">
		<div
			style="
				position: relative;
				padding-bottom: 56.25%;
				height: 0;
				border-radius: 0;
				overflow: hidden;
				min-width: 320px;
				border-radius: 10px 10px 0px 0px;
				/* background-color: white; */
			">
			<div
				ref="handle"
				class="absolute top-[10px] left-[10px] text-white z-[100]">
				<svg
					width="14"
					height="21"
					viewBox="0 0 14 21"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M3.2498 4.90137C2.40605 4.90137 1.70292 4.47949 1.28105 3.77637C0.859172 3.12012 0.859172 2.22949 1.28105 1.52637C1.70292 0.870117 2.40605 0.401367 3.2498 0.401367C4.04667 0.401367 4.7498 0.870117 5.17167 1.52637C5.59355 2.22949 5.59355 3.12012 5.17167 3.77637C4.7498 4.47949 4.04667 4.90137 3.2498 4.90137ZM3.2498 12.4014C2.40605 12.4014 1.70292 11.9795 1.28105 11.2764C0.859172 10.6201 0.859172 9.72949 1.28105 9.02637C1.70292 8.37012 2.40605 7.90137 3.2498 7.90137C4.04667 7.90137 4.7498 8.37012 5.17167 9.02637C5.59355 9.72949 5.59355 10.6201 5.17167 11.2764C4.7498 11.9795 4.04667 12.4014 3.2498 12.4014ZM5.4998 17.6514C5.4998 18.4951 5.03105 19.1982 4.3748 19.6201C3.67167 20.042 2.78105 20.042 2.1248 19.6201C1.42167 19.1982 0.999797 18.4951 0.999797 17.6514C0.999797 16.8545 1.42167 16.1514 2.1248 15.7295C2.78105 15.3076 3.67167 15.3076 4.3748 15.7295C5.03105 16.1514 5.4998 16.8545 5.4998 17.6514ZM10.7498 4.90137C9.90605 4.90137 9.20292 4.47949 8.78105 3.77637C8.35917 3.12012 8.35917 2.22949 8.78105 1.52637C9.20292 0.870117 9.90605 0.401367 10.7498 0.401367C11.5467 0.401367 12.2498 0.870117 12.6717 1.52637C13.0935 2.22949 13.0935 3.12012 12.6717 3.77637C12.2498 4.47949 11.5467 4.90137 10.7498 4.90137ZM12.9998 10.1514C12.9998 10.9951 12.531 11.6982 11.8748 12.1201C11.1717 12.542 10.281 12.542 9.6248 12.1201C8.92167 11.6982 8.4998 10.9951 8.4998 10.1514C8.4998 9.35449 8.92167 8.65137 9.6248 8.22949C10.281 7.80762 11.1717 7.80762 11.8748 8.22949C12.531 8.65137 12.9998 9.35449 12.9998 10.1514ZM10.7498 19.9014C9.90605 19.9014 9.20292 19.4795 8.78105 18.7764C8.35917 18.1201 8.35917 17.2295 8.78105 16.5264C9.20292 15.8701 9.90605 15.4014 10.7498 15.4014C11.5467 15.4014 12.2498 15.8701 12.6717 16.5264C13.0935 17.2295 13.0935 18.1201 12.6717 18.7764C12.2498 19.4795 11.5467 19.9014 10.7498 19.9014Z"
						fill="white" />
				</svg>
			</div>
			<button
				@click.stop="handleClose"
				class="absolute top-[10px] right-[10px] text-white z-[100]">
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M14.9676 3.22949L10.0457 8.15137L14.9676 13.1201C15.5769 13.6826 15.5769 14.667 14.9676 15.2295C14.4051 15.8389 13.4207 15.8389 12.8582 15.2295L7.93632 10.3076L2.96757 15.2295C2.40507 15.8389 1.4207 15.8389 0.858195 15.2295C0.24882 14.667 0.24882 13.6826 0.858195 13.1201L5.78007 8.15137L0.858195 3.22949C0.24882 2.66699 0.24882 1.68262 0.858195 1.12012C1.4207 0.510742 2.40507 0.510742 2.96757 1.12012L7.93632 6.04199L12.8582 1.12012C13.4207 0.510742 14.4051 0.510742 14.9676 1.12012C15.5769 1.68262 15.5769 2.66699 14.9676 3.22949Z"
						fill="white" />
				</svg>
			</button>
			<div class="flex flex-row items-center justify-center">
				<SpinnersThreeDotsBlack :background="'bg-blue-500'" />
			</div>
			<div
				v-if="showVideoLoader"
				id="loader"
				class="absolute top-0 left-0 w-full h-full bg-gray-100 animate-pulse"></div>

			<iframe
				:src="getUrl"
				frameborder="0"
				webkitallowfullscreen
				mozallowfullscreen
				allowfullscreen
				allow="autoplay; fullscreen"
				style="
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 50;
				"
				@load="hideLoader"></iframe>
		</div>
		<div class="bg-[#111827] rounded-b-[10px] p-[20px]">
			<div class="flex flex-col gap-[5px]">
				<div
					class="flex flex-row gap-2 items-center text-xs text-[#D1D5DB]">
					<h1 class="text-xs">36 Views</h1>
					<div class="h-[3px] w-[3px] rounded-full bg-[#D1D5DB]"></div>
					<h1>3 days ago</h1>
				</div>
				<div>
					<h1 class="text-sm text-white">
						{{ store.$state.selectedVideoDetails?.title }}
					</h1>
				</div>
				<div class="flex flex-row gap-[5px] items-center">
					<div>
						<svg
							width="12"
							height="13"
							viewBox="0 0 12 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10.875 6.5C10.875 4.76562 9.9375 3.17188 8.4375 2.28125C6.91406 1.41406 5.0625 1.41406 3.5625 2.28125C2.03906 3.17188 1.125 4.76562 1.125 6.5C1.125 8.25781 2.03906 9.85156 3.5625 10.7422C5.0625 11.6094 6.91406 11.6094 8.4375 10.7422C9.9375 9.85156 10.875 8.25781 10.875 6.5ZM0 6.5C0 4.36719 1.125 2.39844 3 1.32031C4.85156 0.242188 7.125 0.242188 9 1.32031C10.8516 2.39844 12 4.36719 12 6.5C12 8.65625 10.8516 10.625 9 11.7031C7.125 12.7812 4.85156 12.7812 3 11.7031C1.125 10.625 0 8.65625 0 6.5ZM5.4375 3.3125C5.4375 3.00781 5.67188 2.75 6 2.75C6.30469 2.75 6.5625 3.00781 6.5625 3.3125V6.21875L8.55469 7.53125C8.8125 7.71875 8.88281 8.07031 8.71875 8.32812C8.53125 8.58594 8.17969 8.65625 7.92188 8.46875L5.67188 6.96875C5.53125 6.875 5.4375 6.6875 5.4375 6.5V3.3125Z"
								fill="#D1D5DB" />
						</svg>
					</div>
					<h1 class="text-sm text-[#D1D5DB]">03:36</h1>
				</div>
			</div>
		</div>
	</UseDraggable>
</template>
<script setup>
import { UseDraggable } from "@vueuse/components";

const el = ref(null);

const showVideoLoader = ref(true);

const hideLoader = () => {
	showVideoLoader.value = false;
};

const handle = ref(null);
const store = useGlobalStore();

const { x, y, style } = useDraggable(el, {
	initialValue: { x: 0, y: 0 },
});

const handleClose = () => {
	console.log("closed");
	store.setResource({ resource: "showVideoPlayer", value: false });
};

const getUrl = computed(() => {
	const url = store.$state.selectedVideoDetails?.src;
	const splitArr = url.split("/");
	const videoId = splitArr[splitArr.length - 1];
	return "https://video.sendworks.com/share/" + videoId + "/embed";
});
</script>
