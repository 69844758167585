<template>
  <div class="fixed w-screen h-screen bg-black/30 z-10 flex justify-center">
    <div
      v-click-outside="handleModalClose"
      class="p-[25px] w-[800px] h-fit rounded-lg bg-white mt-[50px] flex flex-col"
    >
      <AisInstantSearch :index-name="indexName" :search-client="algolia">
        <div class="w-full">
          <AisSearchBox v-if="tabSelected === 1">
            <template v-slot="{ currentRefinement, isSearchStalled, refine }">
              <InputSearch
                :show-icon="true"
                :value="currentRefinement"
                @input="handleSearch($event.currentTarget.value, refine)"
              />
              <!-- <span :hidden="!isSearchStalled">Loading...</span> -->
            </template>
          </AisSearchBox>
          <InputSearch
            @keyup.enter="searchContact"
            v-if="!isTypesenseSearchEnabled && tabSelected === 2"
            placeholder="Enter a valid email address"
            :show-icon="true"
            :value="searchValue"
            @input="handleContactSearch"
          />
          <InputSearch
            v-if="isTypesenseSearchEnabled && tabSelected === 2"
            placeholder="Search..."
            :show-icon="true"
            :value="searchValue"
            @input="searchContactViaTypesense($event, true)"
          />
        </div>
        <!-- <div class="mt-[20px]">
          <div class="flex flex-row bg-[#F3F4F6] w-full rounded-lg p-[2px]">
            <div
              @click="tabSelected = 1"
              :class="[tabSelected === 1 ? 'bg-white' : '']"
              class="h-[40px] w-1/2 cursor-pointer shadow-sm rounded-md flex flex-row items-center justify-center"
            >
              <h1 class="text-sm">SendX Knowledge base</h1>
            </div>
            <div
              @click="tabSelected = 2"
              :class="[tabSelected === 2 ? 'bg-white' : '']"
              class="h-[40px] w-1/2 cursor-pointer shadow-sm rounded-md flex flex-row items-center justify-center"
            >
              <h1 class="text-sm">Contacts</h1>
            </div>
          </div>
        </div> -->
        <GlobalSearchKnowledgeBase
          :isTyping="isTyping"
          v-if="tabSelected === 1"
        />
        <GlobalSearchContacts
          :query-in-progress="queryInProgress"
          :filtered-contact="filteredContact"
          @reset-value="handleResetValue"
          @show-more="showMoreContacts"
          :searchValue="searchValue"
          :total-count="totalCount"
          v-if="tabSelected === 2"
        />
       
      </AisInstantSearch>
    </div>
  </div>
</template>

<script setup>
import { AisInstantSearch, AisSearchBox } from "vue-instantsearch/vue3/es";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import debounce from "~/utils/debounceFunction";

const indexName = "knowledge_base";
const algolia = useAlgoliaRef();
const store = useGlobalStore();
const searchValue = ref("");
const contactStore = useAudienceContactStore();
const queryInProgress = ref(false);
const filteredContact = ref([]);
const isTyping = ref(false);
const globalStore = useGlobalStore();
const typesenseSearchOffset = ref(0)
const totalCount = ref(0)

const tabSelected = computed(() => {
	return store.$state.searchTabSelected
});

const handleResetValue = () => {
  searchValue.value = "";
};

const handleSearch = (val, refine) => {
  if (val.length > 0) {
    isTyping.value = true;
  } else if (val.length === 0) isTyping.value = false;
  refine(val);
};

const handleContactSearch = (e) => {
  searchValue.value = e.target.value;
  if(searchValue.value.length === 0){
	  filteredContact.value = []
	  return
  }	
  if (isEmailwithParameter(e.target.value)) {
    searchContact();
  }
};

const showMoreContacts = () => {
  typesenseSearchOffset.value += 5
  searchContactViaTypesense(null, false)
}

const handleTypesenseSearch = (e) => {
  searchValue.value = e.target.value;
};

const isEmail = () => {
  var regex =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!regex.test(searchValue.value)) {
    return false;
  } else {
    return true;
  }
};

const isEmailwithParameter = (val) => {
  var regex =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!regex.test(val)) {
    return false;
  } else {
    return true;
  }
};

const isTypesenseSearchEnabled = computed(() => {
  return globalStore.$state.currentTeam?.isTypesenseSearchEnabled;
});

const searchContact = async () => {
  
  if (isEmail()) {
    if (queryInProgress.value === false) {
      queryInProgress.value = true;
      try {
        const res = await contactStore.fetchContactByEmail({
          search: searchValue.value.toLowerCase(),
        });
        filteredContact.value = [];
        if (res !== null && res.email !== "") {
          filteredContact.value.push(res);
        }
        queryInProgress.value = false;
      } catch (err) {
        toast("Error occurred while getting contact by email", {
          theme: "auto",
          type: "error",
          hideProgressBar: false,
          autoClose: 3000,
          transition: "slide",
        });
        handleResetValue();
        queryInProgress.value = false;
        filteredContact.value = [];
      }
    }
  } else {
    toast("Please enter a valid email", {
      theme: "auto",
      type: "error",
      hideProgressBar: false,
      autoClose: 3000,
      transition: "slide",
    });
  }
};

const searchContactViaTypesense = debounce(async (e, resetOffset) => {

  if(e && e.target && e.target.value){
    searchValue.value = e.target.value;
  }
  if(searchValue.value.length === 0){
	  filteredContact.value = []
	  return
  }	

  if(resetOffset){
    typesenseSearchOffset.value = 0;
  }
  if(e && e.target && e.target.value){
    searchValue.value = e.target.value;
  }

  console.log("called with offset: ", typesenseSearchOffset.value, " and search: ", searchValue.value)

  if (searchValue.value?.length === 0) {
    filteredContact.value = [];
    return;
  }

  if (queryInProgress.value === false) {
    queryInProgress.value = true;
    try {
      const res = await contactStore.searchContactViaTypesense({
        query: searchValue.value.toLowerCase(),
        limit: 5,
        offset: typesenseSearchOffset.value,
      });
      totalCount.value = res?.found
      if(res?.hits?.length === 0 || resetOffset){
        filteredContact.value = [];
      }
      for (let hit of res.hits) {
        filteredContact.value.push(hit?.document);
      }

      queryInProgress.value = false;
    } catch (err) {
      toast("Error occurred while getting contact by email", {
        theme: "auto",
        type: "error",
        hideProgressBar: false,
        autoClose: 3000,
        transition: "slide",
      });
      handleResetValue();
      queryInProgress.value = false;
      filteredContact.value = [];
    }
  }
}, 200)

const handleModalClose = () => {
  store.setResource({ resource: "showSearch", value: false });
};
const handleLoad = () => {
  console.log("ais loaded");
};
</script>
